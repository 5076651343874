import React from 'react'
import styled from 'styled-components/macro'
import get from 'lodash.get'
import set from 'lodash.set'
import { cloneDeep } from 'lodash'
import axios from 'axios'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddressCorrectionModal from 'components/common/AddressCorrectionModal'

const ModalFormLabel = styled.span`
  font-weight: bold;
`

/**
 * @param {Object} lookupData - data to be sent to SmartyStreets
 * format: inputId, addressee, street, street2, secondary, urbanization, city, state, zipcode, maxCandidates, match
 * see: https://github.com/smartystreets/smartystreets-javascript-sdk/blob/master/examples/us_street.js
 */
export const checkAddress = async (lookupData) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/address-validations`,
      lookupData,
      { headers: { Accept: '*/*' } }
    )
    return data
  } catch (err) {
    return err
  }
}

/**
 *
 * @param {Object} lookupData - data to be sent to smarty-address-validations
 * format: street, street2, city, state, zipcode
 */
export const getAddress = async (lookupData) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/smarty-address-validations`,
      { headers: { Accept: '*/*' }, params: lookupData }
    )
    return data
  } catch (err) {
    return err
  }
}

export const generateModalContent = (
  addressValues,
  hasError = false,
  isLast = false,
  hasCorrection = false,
  addressKeys
) => {
  return (
    <>
      {hasError && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Address could not be found.
        </Alert>
      )}
      {hasCorrection && (
        <Alert severity="info" sx={{ mb: 2 }}>
          We've made some corrections to the provided address.
        </Alert>
      )}
      <Stack sx={{ mx: 'auto' }}>
        <Typography>
          <ModalFormLabel>Street: </ModalFormLabel>
          {get(addressValues, addressKeys.address)}
          {` ${get(addressValues, addressKeys.addressalt) ?? ''}`}
        </Typography>
        <Typography>
          <ModalFormLabel>City: </ModalFormLabel>
          {get(addressValues, addressKeys.city)}
        </Typography>
        <Typography>
          <ModalFormLabel>State / Province: </ModalFormLabel>
          {get(addressValues, addressKeys.state)}
        </Typography>
        <Typography>
          <ModalFormLabel>Zip / Postal Code: </ModalFormLabel>
          {get(addressValues, addressKeys.zip)}
        </Typography>
        <Typography>
          <ModalFormLabel>Country: </ModalFormLabel>
          {get(addressValues, addressKeys.country)}
        </Typography>
      </Stack>
      {!isLast && <Divider sx={{ my: 2 }} />}
    </>
  )
}

export const generateAddressModalProps = async ({
  values = {},
  addressKeys = {},
  onContinue,
  onCancel,
  forceValid = false,
}) => {
  let modalContent = []

  const vals = cloneDeep(Array.isArray(values) ? values : [values])
  const isMultiple = vals.length > 1
  let updatedValues = []
  let validCheck = []
  let changesMade = false

  for (let i = 0; i < vals.length; i += 1) {
    const val = vals[i]

    const inputAddress = {
      line1: get(val, addressKeys.address),
      line2: get(val, addressKeys.addressalt),
      city: get(val, addressKeys.city),
      state: get(val, addressKeys.state),
      zip: get(val, addressKeys.zip),
      country: get(val, addressKeys.country),
    }

    const response = await checkAddress(inputAddress)

    let addressResponse = null

    addressResponse = await getAddress({
      street: get(val, addressKeys.address),
      street2: get(val, addressKeys.addressalt),
      city: get(val, addressKeys.city),
      state: get(val, addressKeys.state),
      zipcode: get(val, addressKeys.zip),
    })

    if (
      response instanceof Error ||
      (forceValid && addressResponse instanceof Error)
    ) {
      const modalProps = {
        title:
          'The selected address could not be validated, please check your input and try again',
        size: 'sm',
        footerButtonProps: [
          {
            children: 'Repair Address',
            color: 'primary',
            variant: 'outlined',
            onClick: () => onCancel(),
          },
        ],
      }

      if (response?.response?.status === 400) {
        // safe error
        if (response?.response?.data?.display_message) {
          modalProps.children = (
            <Box>
              <Typography mb={6}>
                {response?.response?.data?.display_message}
              </Typography>
            </Box>
          )
        }
      }

      return { modalProps }
    }

    const { address, is_valid: isValid } = response

    validCheck.push(isValid)

    if (addressResponse && !(addressResponse instanceof Error)) {
      const {
        delivery_line_1,
        delivery_line_2,
        components: { city_name, state_abbreviation, zipcode, plus4_code },
      } = addressResponse

      let zip = zipcode

      if (plus4_code) {
        zip = `${zip}-${plus4_code}`
      }

      const missingAddress = !delivery_line_1 && !delivery_line_2

      const outputAddress = {
        line1: missingAddress ? inputAddress.line1 : delivery_line_1,
        line2: missingAddress ? inputAddress.line2 : delivery_line_2,
        city: city_name,
        state: state_abbreviation,
        zip: zip,
        country: 'USA',
      }

      if (isValid && forceValid) {
        set(val, addressKeys.address, delivery_line_1)
        set(val, addressKeys.addressalt, delivery_line_2 || '')
        set(val, addressKeys.city, city_name)
        set(val, addressKeys.state, state_abbreviation)
        set(val, addressKeys.zip, zip)
        set(val, addressKeys.country, 'USA')
      } else {
        const updatedValue = cloneDeep(val)
        set(updatedValue, addressKeys.address, outputAddress.line1)
        set(updatedValue, addressKeys.addressalt, outputAddress.line2)
        set(updatedValue, addressKeys.city, city_name)
        set(updatedValue, addressKeys.state, state_abbreviation)
        set(updatedValue, addressKeys.zip, zip)
        set(updatedValue, addressKeys.country, 'USA')
        updatedValues.push(updatedValue)
      }

      changesMade =
        JSON.stringify(inputAddress) !== JSON.stringify(outputAddress)
    } else if (isValid) {
      set(val, addressKeys.address, address.line1)
      set(val, addressKeys.addressalt, address.line2)
      set(val, addressKeys.city, address.city)
      set(val, addressKeys.state, address.state)
      set(val, addressKeys.zip, address.zip)
      set(val, addressKeys.country, address.country)
    }

    modalContent.push(
      generateModalContent(
        val,
        !isValid,
        i === vals.length - 1,
        changesMade && !isMultiple,
        addressKeys
      )
    )
  }

  const isValid = validCheck.filter((v) => v).length === vals.length
  const isCorrection = isValid && !forceValid && !isMultiple && changesMade

  const modalProps = {
    title: isMultiple
      ? 'One or more of the selected addresses could not be found.'
      : 'The selected address could not be found.',
    size: undefined,
    height: isMultiple ? '90vh' : '464px',
    width: '414px',
    children: modalContent,
    updatedValues,
    addressValues: vals,
    addressKeys,
    onContinue,
    footerButtonProps: [
      !(!isValid && forceValid) &&
        !isCorrection && {
          children: <Box sx={{ fontSize: 12 }}>Continue anyways</Box>,
          color: 'primary',
          variant: 'contained',
          onClick: () => {
            onContinue(vals)
          },
        },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => onCancel(),
      },
    ].filter((b) => b),
  }

  let component = null

  // show different modal if single address with corrections made
  if (isCorrection) {
    component = AddressCorrectionModal
  } else if (isValid) {
    modalProps.footerButtonProps[0].children = 'Continue'
  }

  if (isValid) {
    modalProps.title = isMultiple
      ? 'Are the following addresses correct?'
      : 'Is the following address correct?'
  }

  return { modalProps, component }
}
